import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import "./styles_confirmation.css";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {loadStripe} from "@stripe/stripe-js";
const { v4: uuidv4 } = require('uuid');

const ConfirmationPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const selectedFlight = JSON.parse(decodeURIComponent(queryParams.get('flight')));
    const selectedReturnFlight = queryParams.get('returnFlight') ? JSON.parse(decodeURIComponent(queryParams.get('returnFlight'))) : null;
    const contactDetails = JSON.parse(decodeURIComponent(queryParams.get('contactDetails')));
    const passengerDetails = JSON.parse(decodeURIComponent(queryParams.get('passengerDetails')));
    const adults = parseInt(queryParams.get('adults'));
    const children = parseInt(queryParams.get('children'));
    const infants = parseInt(queryParams.get('infants'));
    const flightType = queryParams.get('flightType');
    const bookingNumber = uuidv4();

    const stripePromise = loadStripe('pk_live_51OpA7EB2BqGOg9dCGLXOPkwzUWyZGHvTeXd1MkZOUNMYjeucNWlPUPZmTlNgEcWMJkNyfKZy9oH7vCEhWZSrcA8b005GwSi7dV'); // Replace with your public key


    function formatTime(dateTimeString) {
        const timeString = dateTimeString.split("T")[1];
        const [hour, minute] = timeString.split(":").slice(0, 2);
        return `${hour}:${minute}`;
    }

    function splitDate(dateTimeString) {
        const [date, time] = dateTimeString.split("T");
        const [year, month, day] = date.split("-");
        const formattedDate = `${day}.${month}.${year}`;
        return { formattedDate };
    }
    function calculatePricePercentage(flight) {
        let percentage = flight.rate.priceList[0].priceChildPercent;
        return percentage < 100 ? 0.01 * percentage : percentage / 100;
    }

    function calculatePricePercentageBaby(flight) {
        let percentage = flight.rate.priceList[0].priceInfantPercent;
        return percentage < 100 ? 0.01 * percentage : percentage / 100;
    }

    function price(flight, returnFlight) {
        const basePrice = adults * ((+(returnFlight.rate.name).replace(".-", "")) + +(flight.rate.name.replace(".-", "")));
        const childrenPrice = children > 0 ? children * ((+(returnFlight.rate.name.replace(".-", "")) * calculatePricePercentage(returnFlight))
            + (+(flight.rate.name.replace(".-", "")) * calculatePricePercentage(flight))) : 0;
        const infantsPrice = infants > 0 ? infants * ((+(returnFlight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(returnFlight))
            + (+(flight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(flight))) : 0;

        const totalPrice = basePrice + childrenPrice + infantsPrice;

        return totalPrice.toFixed(2);
    }

    function priceow(flight) {
        const basePrice = adults * +(flight.rate.name.replace(".-", ""));
        const childrenPrice = children > 0 ? children * (+(flight.rate.name.replace(".-", "")) * calculatePricePercentage(flight)) : 0;
        const infantsPrice = infants > 0 ? infants * (+(flight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(flight)) : 0;

        const totalPrice = basePrice + childrenPrice + infantsPrice;

        return totalPrice.toFixed(2);
    }

    return (
        <div className="confirmation-page">

            <div className="section">
                <div className="hin">
                    <p>{selectedFlight.origin.name.de} - {selectedFlight.destination.name.de}</p>
                    <p>{selectedFlight.number} {splitDate(selectedFlight.departure).formattedDate} {formatTime(selectedFlight.departure)}</p>
                </div>
                {flightType === "return" && (
                <div className="hin">
                    <p>{selectedReturnFlight.origin.name.de} - {selectedReturnFlight.destination.name.de}</p>
                    <p>{selectedReturnFlight.number} {splitDate(selectedReturnFlight.departure).formattedDate} {formatTime(selectedReturnFlight.departure)}</p>
                </div>
                )}
                {flightType === "return" && (
                    <div className="price">
                        <p><strong>Total:</strong> {price(selectedFlight, selectedReturnFlight)}</p>
                    </div>
                )}
                {flightType === "one-way" && (
                    <div className="price">
                        <p><strong>Total:</strong> {priceow(selectedFlight)}</p>
                    </div>
                )}

            </div>

            <div className="section">
                <div className="hin">
                    <p><strong>Informationat kontaktuse</strong></p>
                    <p> {contactDetails.firstName} {contactDetails.lastName}</p>
                    <p>{contactDetails.email} {contactDetails.phone}</p>
                </div>
            </div>

            <div className="section">
                <h3>Passagjeret</h3>
                {passengerDetails.adults.map((adult, index) => (
                    <div key={index} className="passenger-info">
                        <p> {adult.vorname} {adult.nachname} | {adult.geburtstag} | {adult.gender}</p>
                    </div>
                ))}
                {passengerDetails.children.map((child, index) => (
                    <div key={index} className="passenger-info">
                        <p> {child.vorname} {child.nachname} | {child.geburtstag} | {child.gender}</p>
                    </div>
                ))}
                {passengerDetails.infants.map((infant, index) => (
                    <div key={index} className="passenger-info">
                        <p> {infant.vorname} {infant.nachname} | {infant.geburtstag} | {infant.gender}</p>
                    </div>
                ))}
            </div>
            <div className="section">
                <h3>Pagesa</h3>
                <Elements stripe={stripePromise}>
                    {flightType === "return" && (
                    <CheckoutForm
                        totalAmount={price(selectedFlight,selectedReturnFlight) * 100}
                        flightInfo={selectedFlight}
                        returnFlightInfo={selectedReturnFlight}
                        flightType={flightType}
                        adults={adults}
                        children={children}
                        infants={infants}
                        passengerDetails={passengerDetails}
                        contactDetails={contactDetails}
                    />)}
                    {flightType === "one-way" && (
                        <CheckoutForm
                            totalAmount={priceow(selectedFlight) * 100}
                            flightInfo={selectedFlight}
                            flightType={flightType}
                            adults={adults}
                            children={children}
                            infants={infants}
                            passengerDetails={passengerDetails}
                            contactDetails={contactDetails}
                        />)}
                </Elements>
            </div>
        </div>
);
};

export default ConfirmationPage;
