import React, {useState, useEffect, Children} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import "./styles_contact.css"; // Assuming you have a separate CSS file for styling

function ContactDetailsPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate(); // Change history to navigate
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [selectedReturnFlight, setSelectedReturnFlight] = useState(null);
    const [adultsCount, setAdultsCount] = useState(0);
    const [childrenCount, setChildrenCount] = useState(0);
    const [infantsCount, setInfantsCount] = useState(0);
    const [flightType, setFlightType] = useState('');

    useEffect(() => {
        const flight = queryParams.get('flight');
        const returnFlight = queryParams.get('returnFlight');
        const adults = parseInt(queryParams.get('adults')) || 0;
        const children = parseInt(queryParams.get('children')) || 0;
        const infants = parseInt(queryParams.get('infants')) || 0;
        const flightType = queryParams.get('flightType') || '';

        setSelectedFlight(flight ? JSON.parse(decodeURIComponent(flight)) : null);
        setSelectedReturnFlight(returnFlight ? JSON.parse(decodeURIComponent(returnFlight)) : null);
        setAdultsCount(adults);
        setChildrenCount(children);
        setInfantsCount(infants);
        setFlightType(flightType);
    }, [location.search]);

    const [contactDetails, setContactDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    const [passengerDetails, setPassengerDetails] = useState({
        adults: [],
        children: [],
        infants: []
    });

    useEffect(() => {
        const initialAdultsDetails = [];
        for (let i = 0; i < adultsCount; i++) {
            initialAdultsDetails.push({ vorname: '', nachname: '', geburtstag: '', gender: '' });
        }
        setPassengerDetails(prevDetails => ({
            ...prevDetails,
            adults: initialAdultsDetails
        }));

        const initialChildrenDetails = [];
        for (let i = 0; i < childrenCount; i++) {
            initialChildrenDetails.push({ vorname: '', nachname: '', geburtstag: '', gender: '' });
        }
        setPassengerDetails(prevDetails => ({
            ...prevDetails,
            children: initialChildrenDetails
        }));

        const initialInfantsDetails = [];
        for (let i = 0; i < infantsCount; i++) {
            initialInfantsDetails.push({ vorname: '', nachname: '', geburtstag: '', gender: '' });
        }
        setPassengerDetails(prevDetails => ({
            ...prevDetails,
            infants: initialInfantsDetails
        }));
    }, [adultsCount, childrenCount, infantsCount]);

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handlePassengerChange = (category, index, e) => {
        const { name, value } = e.target;
        setPassengerDetails(prevDetails => {
            const newDetails = { ...prevDetails };
            newDetails[category][index] = {
                ...newDetails[category][index],
                [name]: value
            };
            return newDetails;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to the server or navigate to the next pag

        const encodedSelectedFlight = encodeURIComponent(JSON.stringify(selectedFlight));
        const encodedcontactDetails = encodeURIComponent(JSON.stringify(contactDetails));
        const encodedpassengerDetails = encodeURIComponent(JSON.stringify(passengerDetails));
        let url = `/confirmation?flight=${encodedSelectedFlight}&contactDetails=${encodedcontactDetails}&passengerDetails=${encodedpassengerDetails}&adults=${parseInt(queryParams.get('adults'))}&children=${parseInt(queryParams.get('children'))}&infants=${parseInt(queryParams.get('infants'))}&flightType=${flightType}`;

        if (flightType === 'return') {
            const encodedSelectedReturnFlight = encodeURIComponent(JSON.stringify(selectedReturnFlight));
            url += `&returnFlight=${encodedSelectedReturnFlight}`;
        }

        navigate(url);// Navigate to the next page or show a confirmation message
    };

    return (
        <div className="contact-details-page">
            <form onSubmit={handleSubmit}>
                <div className="Passagiere">
                    <h2>Shënoni të dhënat e pasagjerit</h2>
                    <div className="form-row">
                        <div className="form-group-half">
                            <label htmlFor="firstName">Emri</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={contactDetails.firstName}
                                onChange={handleContactChange}
                                required
                            />
                        </div>
                        <div className="form-group-half">
                            <label htmlFor="lastName">Mbiemri</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={contactDetails.lastName}
                                onChange={handleContactChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group-half">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={contactDetails.email}
                                onChange={handleContactChange}
                                required
                            />
                        </div>
                        <div className="form-group-half">
                            <label htmlFor="phone">Mobili</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={contactDetails.phone}
                                onChange={handleContactChange}
                                required
                            />
                        </div>
                    </div>
                </div>

                {/* Adults Details */}
                {passengerDetails.adults.map((adult, index) => (
                    <div key={index} className="Passagiere">
                        <h3>I rritur {index + 1}</h3>
                        <div className="form-row">
                            <div className="form-group-half">
                                <label htmlFor={`vorname-adult-${index}`}>Emri</label>
                                <input
                                    type="text"
                                    id={`vorname-adult-${index}`}
                                    name="vorname"
                                    value={adult.vorname}
                                    onChange={(e) => handlePassengerChange('adults', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`nachname-adult-${index}`}>Mbiemri</label>
                                <input
                                    type="text"
                                    id={`nachname-adult-${index}`}
                                    name="nachname"
                                    value={adult.nachname}
                                    onChange={(e) => handlePassengerChange('adults', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`geburtstag-adult-${index}`}>Datëlindja</label>
                                <input
                                    type="date"
                                    id={`geburtstag-adult-${index}`}
                                    name="geburtstag"
                                    value={adult.geburtstag}
                                    onChange={(e) => handlePassengerChange('adults', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`gender-adult-${index}`}>Gjinia</label>
                                <select
                                    id={`gender-adult-${index}`}
                                    name="gender"
                                    value={adult.gender}
                                    onChange={(e) => handlePassengerChange('adults', index, e)}
                                    required
                                >
                                    <option value="vari">Gjinia</option>
                                    <option value="männlich">Mashkull</option>
                                    <option value="weiblich">Femer</option>
                                </select>
                            </div>
                        </div>
                    </div>
                ))}

                {/* Children Details */}
                {passengerDetails.children.map((child, index) => (
                    <div key={index} className="Passagiere">
                        <h3>Fëmij {index + 1}</h3>
                        <div className="form-row">
                            <div className="form-group-half">
                                <label htmlFor={`vorname-child-${index}`}>Emer</label>
                                <input
                                    type="text"
                                    id={`vorname-child-${index}`}
                                    name="vorname"
                                    value={child.vorname}
                                    onChange={(e) => handlePassengerChange('children', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`nachname-child-${index}`}>Mbiemer</label>
                                <input
                                    type="text"
                                    id={`nachname-child-${index}`}
                                    name="nachname"
                                    value={child.nachname}
                                    onChange={(e) => handlePassengerChange('children', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`geburtstag-child-${index}`}>Datëlindja</label>
                                <input
                                    type="date"
                                    id={`geburtstag-child-${index}`}
                                    name="geburtstag"
                                    value={child.geburtstag}
                                    onChange={(e) => handlePassengerChange('children', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`gender-child-${index}`}>Gjinia</label>
                                <select
                                    id={`gender-child-${index}`}
                                    name="gender"
                                    value={child.gender}
                                    onChange={(e) => handlePassengerChange('children', index, e)}
                                    required
                                >
                                    <option value="vari">Gjinia</option>
                                    <option value="männlich">Mashkull</option>
                                    <option value="weiblich">Femer</option>
                                </select>
                            </div>
                        </div>
                    </div>
                ))}

                {/* Infant Details */}
                {passengerDetails.infants.map((infant, index) => (
                    <div key={index} className="Passagiere">
                        <h3>Foshnje {index + 1}</h3>
                        <div className="form-row">
                            <div className="form-group-half">
                                <label htmlFor={`vorname-infant-${index}`}>Emer</label>
                                <input
                                    type="text"
                                    id={`vorname-infant-${index}`}
                                    name="vorname"
                                    value={infant.vorname}
                                    onChange={(e) => handlePassengerChange('infants', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`nachname-infant-${index}`}>Mbiemer</label>
                                <input
                                    type="text"
                                    id={`nachname-infant-${index}`}
                                    name="nachname"
                                    value={infant.nachname}
                                    onChange={(e) => handlePassengerChange('infants', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`geburtstag-infant-${index}`}>Datëlindja</label>
                                <input
                                    type="date"
                                    id={`geburtstag-infant-${index}`}
                                    name="geburtstag"
                                    value={infant.geburtstag}
                                    onChange={(e) => handlePassengerChange('infants', index, e)}
                                    required
                                />
                            </div>
                            <div className="form-group-half">
                                <label htmlFor={`gender-infant-${index}`}>Gjinia</label>
                                <select
                                    id={`gender-infant-${index}`}
                                    name="gender"
                                    value={infant.gender}
                                    onChange={(e) => handlePassengerChange('infants', index, e)}
                                    required
                                >
                                    <option value="vari">Gjinia</option>
                                    <option value="männlich">Mashkull</option>
                                    <option value="weiblich">Femer</option>
                                </select>
                            </div>
                        </div>
                    </div>

                ))}
                <div>
                    {/* Example buttons or triggers to move to the conweifirmation page */}
                    <button type="submit" className="btn btn-primary">Vazhdo</button>
                </div>
            </form>
        </div>
    );
}

export default ContactDetailsPage;

