import React from 'react';

const Datenschutz = () => {
    return (
        <html lang="sq">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Deklarata e Privatësisë - Roel Travel</title>
            <style>
                {`
                    body {
                      
                    }
                    .container {
                            background-color: rgba(255, 255, 255, 0.8);
                            color: #000000;
                            display: flex;
                            align-items: center;
                            margin-bottom: 2px;
                            width: 60%;
                            height: 95%;
                            position: absolute;
                            top: 60%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            borderRadius: 8;
                    }
                    h1, h2 {
                        color: #333;
                    }
                    `}
            </style>
        </head>
        <body>
        <div className="container">
            <div id="privacy">
                <h2>1. Informacioni i Përgjithshëm</h2>
                <p>Kjo deklaratë e privatësisë shpjegon se si Roel Travel mbledh, përdor, dhe mbron të dhënat tuaja personale kur përdorni faqen tonë të internetit dhe shërbimet tona.</p>

                <h2>2. Mbledhja e të Dhënave</h2>
                <p>Ne mbledhim të dhëna personale që ju na ofroni kur bëni një rezervim, krijoni një llogari, ose na kontaktoni. Këto të dhëna mund të përfshijnë emrin tuaj, adresën, email-in, numrin e telefonit, dhe informacionin e pagesës.</p>

                <h2>3. Përdorimi i të Dhënave</h2>
                <p>Ne përdorim të dhënat tuaja për të përpunuar rezervimet tuaja, për t'ju ofruar shërbimet tona, dhe për t'ju kontaktuar në lidhje me rezervimet ose shërbimet tona. Ne gjithashtu mund t'i përdorim të dhënat tuaja për qëllime marketingu, nëse na keni dhënë pëlqimin tuaj.</p>

                <h2>4. Ruajtja dhe Mbrojtja e të Dhënave</h2>
                <p>Ne marrim masa të përshtatshme teknike dhe organizative për të mbrojtur të dhënat tuaja nga akses i paautorizuar, humbja, ose shkatërrimi. Të dhënat tuaja ruhen në servera të sigurt dhe janë të mbrojtura me teknologji të kriptimit.</p>

                <h2>5. Shkëmbimi i të Dhënave</h2>
                <p>Ne nuk i ndajmë të dhënat tuaja personale me palë të treta, përveç kur është e nevojshme për të përpunuar rezervimet tuaja ose kur kërkohet nga ligji.</p>

                <h2>6. Të Drejtat Tuaja</h2>
                <p>Ju keni të drejtë të kërkoni akses në të dhënat tuaja personale, t'i korrigjoni ato, t'i fshini ose t'i kufizoni përpunimin e tyre. Për të ushtruar këto të drejta, ju lutemi na kontaktoni në adresën e email-it të mëposhtme.</p>

                <h2>7. Ndryshimet në Deklaratën e Privatësisë</h2>
                <p>Ne mund të ndryshojmë këtë deklaratë të privatësisë në çdo kohë. Çdo ndryshim do të publikohet në këtë faqe dhe do të hyjë në fuqi menjëherë pas publikimit.</p>

                <h2>8. Kontakt</h2>
                <p>Nëse keni ndonjë pyetje në lidhje me këtë deklaratë të privatësisë ose mënyrën se si ne trajtojmë të dhënat tuaja personale, ju lutemi na kontaktoni në:</p>
                <p><strong>Roel Travel</strong><br />
                    Adresa: Mülibachstrasse 1, 8185 Winkel<br />
                    Email: info@roeltravel.ch<br />
                    Telefon: +41 78 308 08 07 </p>
            </div>
        </div>
        </body>
        </html>
    );
}

export default Datenschutz;
