import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import "./styles_results.css";


function decodeAirportCode(code) {
    const airportCodes = {
        MLH: 'BASEL/Mulhouse',
        PRN: 'PRISHTINA',
        SKP: 'SKOPJE',
        GVA: 'GENEVE/Genf',
        ZRH: 'ZÜRICH'
        // Add more airport codes and names as needed
    };

    return airportCodes[code] || 'Unknown Airport';
}


function calculatePricePercentage(flight) {
    let percentage = flight.rate.priceList[0].priceChildPercent;
    return percentage < 100 ? 0.01 * percentage : percentage / 100;
}

function calculatePricePercentageBaby(flight) {
    let percentage = flight.rate.priceList[0].priceInfantPercent;
    return percentage < 100 ? 0.01 * percentage : percentage / 100;
}

function formatTime(dateTimeString) {
    const timeString = dateTimeString.split("T")[1];
    const [hour, minute] = timeString.split(":").slice(0, 2);
    return `${hour}:${minute}`;
}

function splitDate(dateTimeString) {
    const [date, time] = dateTimeString.split("T");
    const [year, month, day] = date.split("-");
    const formattedDate = `${day}.${month}.${year}`;
    return { formattedDate };
}

function FlightResultsPage() {
    const [flightData, setFlightData] = useState([]);
    const [returnData, setReturnData] = useState([]);
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [selectedReturnFlight, setSelectedReturnFlight] = useState(null);


    const handleFlightSelection = (flight) => {
        if (flight.rate.priceList[0].hasAvailableSeats) {
            setSelectedFlight(flight);
        }
    };

    function handleReturnFlightSelection (flight){
        if (flight.rate.priceList[0].hasAvailableSeats) {
            setSelectedReturnFlight(flight);
        }

     }
    const navigate = useNavigate(); // Change history to navigate
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const departureDate = queryParams.get('departureDate') || '';
    const returnDate = queryParams.get('returnDate') || '';
    const departureAirport = queryParams.get('departureAirport') || '';
    const arrivalAirport = queryParams.get('arrivalAirport') || '';
    const adults = queryParams.get('adults') || 0;
    const children = queryParams.get('children') || 0;
    const infants = queryParams.get('infants') || 0;
    const flightType = queryParams.get('flightType') || '';



    const handleSubmit = (e) => {
        e.preventDefault();
        const encodedSelectedFlight = encodeURIComponent(JSON.stringify(selectedFlight));
        let url = `/contact-details?flight=${encodedSelectedFlight}&adults=${adults}&children=${children}&infants=${infants}&flightType=${flightType}`;

        if (flightType === 'return') {
            const encodedSelectedReturnFlight = encodeURIComponent(JSON.stringify(selectedReturnFlight));
            url += `&returnFlight=${encodedSelectedReturnFlight}`;
        }

        navigate(url);
    };

    useEffect(() => {
        async function fetchFlightData() {
            const departureDateTime = new Date(departureDate);

            // Calculate the date 5 days after returnDate
            const fiveDaysAfterDeparture = new Date(departureDateTime);
            fiveDaysAfterDeparture.setDate(departureDateTime.getDate() + 3);

            // Format the fiveDaysAfterReturn date in the desired format (e.g., YYYY-MM-DD)
            const formattedFiveDaysAfterDeparture = `${fiveDaysAfterDeparture.getFullYear()}-${String(fiveDaysAfterDeparture.getMonth() + 1).padStart(2, '0')}-${String(fiveDaysAfterDeparture.getDate()).padStart(2, '0')}`;
            const url = `https://c416-2a02-aa11-27e-6300-1119-7c05-66c8-8246.ngrok-free.app/proxy?departureDate=${departureDate}&formattedFiveDays=${formattedFiveDaysAfterDeparture}&departureAirport=${departureAirport}&arrivalAirport=${arrivalAirport}&pageSize=100&passengersAgeConfig=%5B%7B%22ageType%22%3A%22adult%22%7D%5D&sortBy=departure`;
            //const url  = `'https://cors-anywhere.herokuapp.com/https://www.malesiareisen.com/booking/flight/select-flight-outbound?travelType=return&departureDate=${departureDate}&returnDate=${formattedFiveDaysAfterDeparture}&airportFrom=${departureAirport}&airportTo=${arrivalAirport}&adult=${adults}&child=${children}&infant=${infants}`
            try {
                const response = await fetch(url, {headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    })})
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);
                setFlightData(data.items);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        }

        fetchFlightData();
    }, [departureDate, returnDate, departureAirport, arrivalAirport, adults, children, infants, flightType]);

    useEffect(() => {
        async function fetchFlightDataReturn() {
            const returnDateTime = new Date(returnDate);

            // Calculate the date 5 days after returnDate
            const fiveDaysAfterReturn = new Date(returnDateTime);
            fiveDaysAfterReturn.setDate(returnDateTime.getDate() + 3);

            // Format the fiveDaysAfterReturn date in the desired format (e.g., YYYY-MM-DD)
            const formattedFiveDaysAfterReturn = `${fiveDaysAfterReturn.getFullYear()}-${String(fiveDaysAfterReturn.getMonth() + 1).padStart(2, '0')}-${String(fiveDaysAfterReturn.getDate()).padStart(2, '0')}`;
            if (flightType === "return") {
                const urlReturn = `https://c416-2a02-aa11-27e-6300-1119-7c05-66c8-8246.ngrok-free.app/proxy?departureDate=${returnDate}&formattedFiveDays=${formattedFiveDaysAfterReturn}&departureAirport=${arrivalAirport}&arrivalAirport=${departureAirport}&pageSize=100&passengersAgeConfig=%5B%7B%22ageType%22%3A%22adult%22%7D%5D&sortBy=departure`;
                try {
                    const responseReturn = await fetch(urlReturn, {headers: new Headers({
                            "ngrok-skip-browser-warning": "69420",
                        })})
                    if (!responseReturn.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await responseReturn.json();
                    setReturnData(data.items);
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            }
        }

        fetchFlightDataReturn();
    }, [departureDate, returnDate, departureAirport, arrivalAirport, adults, children, infants, flightType]);

    if (!flightData.length) {
        return <div>Loading...</div>;
    }

    function price(flight, returnFlight) {
        const basePrice = adults * ((+(returnFlight.rate.name).replace(".-", "")) + +(flight.rate.name.replace(".-", "")));
        const childrenPrice = children > 0 ? children * ((+(returnFlight.rate.name.replace(".-", "")) * calculatePricePercentage(returnFlight))
            + (+(flight.rate.name.replace(".-", "")) * calculatePricePercentage(flight))) : 0;
        const infantsPrice = infants > 0 ? infants * ((+(returnFlight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(returnFlight))
            + (+(flight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(flight))) : 0;

        const totalPrice = basePrice + childrenPrice + infantsPrice;

        return totalPrice.toFixed(2);
    }

    function priceow(flight) {
        const basePrice = adults * +(flight.rate.name.replace(".-", ""));
        const childrenPrice = children > 0 ? children * (+(flight.rate.name.replace(".-", "")) * calculatePricePercentage(flight)) : 0;
        const infantsPrice = infants > 0 ? infants * (+(flight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(flight)) : 0;

        const totalPrice = basePrice + childrenPrice + infantsPrice;

        return totalPrice.toFixed(2);
    }

    // Convert departure date string to Date object
    const departureDateTime = new Date(departureDate);

    // Calculate the date four days from departure
    const fourDaysAfterDeparture = new Date(departureDateTime);
    fourDaysAfterDeparture.setDate(departureDateTime.getDate() + 5);

    // Filter flights within four days from departure
    const filteredFlights = flightData.filter(flight => {
        const flightDepartureDateTime = new Date(flight.departure);
        return flightDepartureDateTime >= departureDateTime && flightDepartureDateTime <= fourDaysAfterDeparture;
    });

    if (flightType === "one-way") {
        return (
            <div className="flight-results">
                <h2 className="page-sub-title__title">
                    <span className="page-sub-title__icon">
                        <div className="icon">
                            <svg className="airplane" name="airplane" width="1em" height="1em" shapeRendering="auto">
                                <use href="/svg/sprites.svg#airplane"></use>
                            </svg>
                        </div>
                    </span>
                    <span
                        className="page-sub-title__text">{decodeAirportCode(departureAirport)} - {decodeAirportCode(arrivalAirport)}</span>
                </h2>
                <form onSubmit={handleSubmit}>
                    <table>
                        <tbody>
                        {flightData.map((flight, index) => (
                            <tr key={index} className={selectedFlight === flight ? "selected" : ""}
                                onClick={() => handleFlightSelection(flight)}>
                                <td>
                                    <div className="date"> {splitDate(flight.departure).formattedDate}</div>
                                </td>
                                <td colSpan="5">{departureAirport}<br/>
                                    <div className="time"> {formatTime(flight.departure)}</div>
                                </td>
                                <td colSpan="5">{arrivalAirport}<br/>
                                    <div className="time"> {formatTime(flight.arrival)}</div>
                                </td>
                                <td colSpan="5">
                                    <div className="time"> {flight.number}</div>
                                    <br/>
                                    {flight.flightCompany.name}
                                </td>
                                <td>
                                    {console.log(flight.rate.priceList[0].hasAvailableSeats)}
                                    {flight.rate.priceList[0].hasAvailableSeats  ? (
                                        <div className="time selectable">{+(flight.rate.name.replace(".-", ""))} CHF</div>
                                    ) : (
                                        <div className="time sold-out">SOLD OUT</div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {selectedFlight && (
                        <div className="selected-flights-one">
                            <div className="hin">
                                <p>{selectedFlight.origin.name.de} - {selectedFlight.destination.name.de}</p>
                                <p>{selectedFlight.number} {splitDate(selectedFlight.departure).formattedDate} {formatTime(selectedFlight.departure)}</p>
                            </div>
                            <div className="hin">
                                <h5>Passagiere</h5>
                                <p>{adults}x I
                                    rritur: {adults * (+(selectedFlight.rate.name.replace(".-", "")))} CHF</p>
                                <p>
                                    {children > 0 && (
                                        <>
                                            {children}x
                                            Fëmijë: {children * (+(selectedFlight.rate.name.replace(".-", "")) * calculatePricePercentage(selectedFlight)).toFixed(2)} CHF
                                        </>
                                    )}
                                </p>
                                <p>
                                    {infants > 0 && (
                                        <>
                                            {infants}x
                                            Baby: {infants * ((+(selectedFlight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(selectedFlight)).toFixed(2))} CHF
                                        </>
                                    )}
                                </p>
                            </div>

                            <p>------------------------------</p>
                            <div className="price">
                                <p> Gesamt: {priceow(selectedFlight)}</p>
                            </div>
                        </div>
                    )}
                    <div>
                        {/* Example buttons or triggers to move to the confirmation page */}
                        <button type="submit" className="btn btn-primary">Vazhdo</button>
                    </div>
                </form>
            </div>
    );
    } else {
        return (
            <div className="flight-results">
                <h2 className="page-sub-title__title">
                    <span className="page-sub-title__icon">
                        <div className="icon">
                            <svg className="airplane" name="airplane" width="1em" height="1em" shapeRendering="auto">
                                <use href="/svg/sprites.svg#airplane"></use>
                            </svg>
                        </div>
                    </span>
                    <span
                        className="page-sub-title__text">{decodeAirportCode(departureAirport)} - {decodeAirportCode(arrivalAirport)}</span>
                </h2>
                <table>
                    <tbody>
                    {filteredFlights.map((flight, index) => (
                        <tr key={index} className={selectedFlight === flight ? "selected" : ""}
                            onClick={() => handleFlightSelection(flight)}>
                            <td>
                                <div className="date"> {splitDate(flight.departure).formattedDate}</div>
                            </td>
                            <td colSpan="5">{departureAirport}<br/>
                                <div className="time"> {formatTime(flight.departure)}</div>
                            </td>
                            <td colSpan="5">{arrivalAirport}<br/>
                                <div className="time"> {formatTime(flight.arrival)}</div>
                            </td>
                            <td colSpan="5">
                                <div className="time"> {flight.number}</div>
                                <br/>
                                {flight.flightCompany.name}
                            </td>
                            <td>
                                {flight.rate.priceList[0].hasAvailableSeats ? (
                                    <div className="time selectable">{+(flight.rate.name.replace(".-", ""))} CHF</div>
                                ) : (
                                    <div className="time sold-out">SOLD OUT</div>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <div className="flight-results-return">
                    <h2 className="page-sub-title__title">
                        <span className="page-sub-title__icon">
                            <div className="icon">
                                <svg className="airplane" name="airplane" width="1em" height="1em"
                                     shapeRendering="auto">
                                    <use href="/svg/sprites.svg#airplane"></use>
                                </svg>
                            </div>
                        </span>
                        <span
                            className="page-sub-title__text">{decodeAirportCode(arrivalAirport)} - {decodeAirportCode(departureAirport)}</span>
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <table>
                            <tbody>
                            {returnData.map((flight, index) => (
                                <tr key={index} className={selectedReturnFlight === flight ? "selected" : ""}
                                    onClick={() => handleReturnFlightSelection(flight)}>
                                    <td>
                                        <div className="date"> {splitDate(flight.departure).formattedDate}</div>
                                    </td>
                                    <td colSpan="5">{arrivalAirport}<br/>
                                        <div className="time"> {formatTime(flight.departure)}</div>
                                    </td>
                                    <td colSpan="5">{departureAirport}<br/>
                                        <div className="time"> {formatTime(flight.arrival)}</div>
                                    </td>
                                    <td colSpan="5">
                                        <div className="time"> {flight.number}</div>
                                        <br/>
                                        {flight.flightCompany.name}
                                    </td>
                                    <td>
                                        {console.log(flight.rate.priceList[0].hasAvailableSeats)}
                                        {flight.rate.priceList[0].hasAvailableSeats ? (
                                            <div
                                                className="time selectable">{+(flight.rate.name.replace(".-", ""))} CHF</div>
                                        ) : (
                                            <div className="time sold-out">SOLD OUT</div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div>
                            {/* Example buttons or triggers to move to the conweifirmation page */}
                            <button type="submit" className="btn btn-primary">Vazhdo</button>
                        </div>
                    </form>

                    {selectedReturnFlight && selectedFlight && (
                        <div className="selected-flights">
                            <div className="hin">
                                <p>{selectedFlight.origin.name.de} - {selectedFlight.destination.name.de}</p>
                                <p>{selectedFlight.number} {splitDate(selectedFlight.departure).formattedDate} {formatTime(selectedFlight.departure)}</p>
                            </div>
                            <div className="hin">
                                <p>{selectedReturnFlight.origin.name.de} - {selectedReturnFlight.destination.name.de}</p>
                                <p>{selectedReturnFlight.number} {splitDate(selectedReturnFlight.departure).formattedDate} {formatTime(selectedReturnFlight.departure)}</p>
                            </div>
                            <div className="hin">
                                <h5>Passagjeret</h5>
                                <p>{adults}x I
                                    rritur: {adults * (+(selectedReturnFlight.rate.name.replace(".-", "")) + +(selectedFlight.rate.name.replace(".-", "")))} CHF</p>
                                <p>
                                {children > 0 && (
                                        <>
                                            {children}x
                                            Fëmijë: {children * ((+(selectedReturnFlight.rate.name.replace(".-", "")) * calculatePricePercentage(selectedReturnFlight))
                                            + (+(selectedFlight.rate.name.replace(".-", "")) * calculatePricePercentage(selectedFlight))).toFixed(2)} CHF
                                        </>
                                    )}
                                </p>
                                <p>
                                    {infants > 0 && (
                                        <>
                                            {infants}x
                                            Baby: {infants * ((+(selectedReturnFlight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(selectedReturnFlight))
                                            + (+(selectedFlight.rate.name.replace(".-", "")) * calculatePricePercentageBaby(selectedFlight))).toFixed(4)} CHF
                                        </>
                                    )}
                                </p>
                            </div>

                            <p>---------------------------------------------------</p>
                            <div className="price">
                                <p> Total: {price(selectedFlight, selectedReturnFlight)}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default FlightResultsPage;
