import React from 'react';

const About = () => {
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        margin: '0',
        padding: '20px',
        backgroundColor: '#f0f0f0',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    };

    const sectionStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        width: '80%',
        maxWidth: '800px'
    };

    const headingStyle = {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '20px',
        color: '#333'
    };

    const textStyle = {
        fontSize: '20px',
        lineHeight: '1.6',
        marginBottom: '15px',
        textAlign: 'left'
    };

    return (
        <div style={containerStyle}>
            <h1>Über Roel Travel</h1>
            <div style={sectionStyle}>
                <h2 style={headingStyle}>Willkommen bei Roel Travel</h2>
                <p style={textStyle}>Roel Travel ist Ihr zuverlässiger Partner für unvergessliche Reiseerlebnisse. Seit unserer Gründung haben wir es uns zur Aufgabe gemacht, unseren Kunden exzellenten Service und maßgeschneiderte Reiseangebote zu bieten. Unser erfahrenes Team steht Ihnen bei der Planung und Durchführung Ihrer Reise jederzeit zur Seite.</p>
                <p style={textStyle}>Bei Roel Travel legen wir großen Wert auf Qualität, Zuverlässigkeit und Kundenzufriedenheit. Wir arbeiten eng mit einer Vielzahl von Partnern zusammen, um Ihnen die bestmöglichen Reiseoptionen zu bieten, sei es für geschäftliche Angelegenheiten, Familienurlaube oder individuelle Abenteuer.</p>
                <p style={textStyle}>Unser Portfolio umfasst eine breite Palette an Dienstleistungen. Egal, ob Sie eine Last-Minute-Reise planen oder langfristig eine Reise für eine große Gruppe organisieren möchten - Roel Travel steht Ihnen mit Fachwissen und Engagement zur Seite.</p>
                <p style={textStyle}>Kontaktieren Sie uns heute, um Ihre nächste Reise zu planen und mit uns einzigartige Erinnerungen zu schaffen:</p>
                <p style={textStyle}>E-Mail: info@roeltravel.ch</p>
                <p style={textStyle}>Web: www.roeltravel.ch</p>
            </div>
        </div>
    );
};

export default About;
