import React from 'react';

const AGB = () => {
    return (
        <html lang="sq">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Kushtet e Përgjithshme të Biznesit (KPB) - Roel Travel</title>
            <style>
                {`
                    body {
                      
                    }
                       .container {
                            background-color: rgba(255, 255, 255, 0.8);
                            color: #000000;
                            display: flex;
                            align-items: center;
                            margin-bottom: 2px;
                            width: 60%;
                            height: 125%;
                            position: absolute;
                            top: 80%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            borderRadius: 8;
                    }
                    
                    h1, h2 {
                        color: #333;
                    }
                    `}
            </style>
        </head>
        <body>
        <div className="container">
            <div id="terms">
                <h2>1. Hyrje</h2>
                <p>Këto kushte të përgjithshme të biznesit (KPB) rregullojnë marrëdhëniet kontraktuale midis Roel Travel dhe klientëve të saj. Me bërjen e një rezervimi, klienti pranon këto kushte.</p>

                <h2>2. Shërbimet e ofruara</h2>
                <p>Roel Travel ofron shërbime të udhëtimit, duke përfshirë rezervimin e fluturimeve, akomodimit, dhe aktiviteteve të tjera turistike. Të gjitha shërbimet janë subjekt i disponueshmërisë dhe konfirmimit nga furnizuesit tanë.</p>

                <h2>3. Rezervimi dhe pagesa</h2>
                <p><strong>3.1 Rezervimi:</strong> Të gjitha rezervimet duhet të bëhen nëpërmjet faqes sonë të internetit ose duke na kontaktuar direkt.</p>
                <p><strong>3.2 Pagesa:</strong> Pagesa e plotë duhet të bëhet në kohën e rezervimit, përveç rasteve kur është rënë dakord ndryshe. Ne pranojmë metodat e pagesës të treguara në faqen tonë të internetit.</p>

                <h2>4. Anulimi dhe ndryshimet</h2>
                <p><strong>4.1 Anulimi nga klienti:</strong> Nëse dëshironi të anuloni rezervimin tuaj, duhet të na njoftoni me shkrim. Tarifat e anulimit do të zbatohen si më poshtë:</p>
                <ul>
                    <li>Më shumë se 30 ditë para nisjes: 10% e çmimit të plotë</li>
                    <li>15-30 ditë para nisjes: 50% e çmimit të plotë</li>
                    <li>Më pak se 15 ditë para nisjes: 100% e çmimit të plotë</li>
                </ul>
                <p><strong>4.2 Ndryshimet:</strong> Çdo kërkesë për ndryshime në rezervimin tuaj do të trajtohet në bazë të disponueshmërisë dhe mund të jetë subjekt i tarifave shtesë.</p>

                <h2>5. Detyrimet e klientit</h2>
                <p>Klienti është përgjegjës për sigurimin e dokumenteve të nevojshme për udhëtim (p.sh., pasaporta, vizat) dhe për respektimin e rregullave të hyrjes dhe të daljeve të vendeve që do të vizitohen.</p>

                <h2>6. Përgjegjësia e Roel Travel</h2>
                <p>Roel Travel do të bëjë çdo përpjekje për të siguruar që udhëtimet dhe shërbimet e ofruara të jenë të një cilësie të lartë. Megjithatë, ne nuk jemi përgjegjës për vonesa, anulime, ose ndryshime të bëra nga furnizuesit tanë.</p>

                <h2>7. Sigurimi</h2>
                <p>Ne rekomandojmë fuqimisht që klientët të marrin një sigurim të udhëtimit që mbulon anulimet, shpenzimet mjekësore dhe humbjen e bagazhit.</p>

                <h2>8. Të drejtat e pronësisë intelektuale</h2>
                <p>Të gjitha materialet dhe përmbajtjet në faqen tonë të internetit janë pronë e Roel Travel ose janë të licencuara nga ne. Klientët nuk kanë të drejtë të kopjojnë, shpërndajnë, ose të shfrytëzojnë materialet tona pa lejen tonë të shkruar.</p>

                <h2>9. Ndryshimet në KPB</h2>
                <p>Roel Travel rezervon të drejtën të ndryshojë këto KPB në çdo kohë. Ndryshimet do të publikohen në faqen tonë të internetit dhe do të hyjnë në fuqi menjëherë pas publikimit.</p>

                <h2>10. Ligji i zbatueshëm dhe juridiksioni</h2>
                <p>Këto KPB janë subjekt i ligjit të Zvicrës. Çdo mosmarrëveshje që del nga ose në lidhje me këto KPB do të zgjidhet nga gjykatat kompetente në Zvicër.</p>

                <p><strong>Roel Travel</strong><br />
                    Adresa: Mülibachstrasse 1, 8185 Winkel<br />
                    Email: info@roeltravel.ch<br />
                    Telefon: +41 78 308 08 07</p>
            </div>
        </div>
        </body>
        </html>
    );
}

export default AGB;
