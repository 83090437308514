import React from 'react';
import "./styles_confirmation.css";
import { v4 as uuidv4 } from 'uuid';


const CheckoutForm = ({
                          totalAmount,
                          flightInfo,
                          returnFlightInfo,
                          flightType,
                          adults,
                          children,
                          infants,
                          passengerDetails,
                          contactDetails
                      }) => {
    const bookingNumber = Date.now();

    const handleClick = async (event) => {
        const payload = {
            totalAmount,
            flightInfo,
            adults,
            children,
            infants,
            passengerDetails,
            contactDetails,
            bookingNumber,
        };

        if (flightType === 'return') {
            payload.returnFlightInfo = returnFlightInfo;
        }

        try {
            const response = await fetch('https://c416-2a02-aa11-27e-6300-1119-7c05-66c8-8246.ngrok-free.app/process-booking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const session = await response.json();
            window.location = session.sessionUrl; // Use sessionUrl to redirect
        } catch (error) {
            console.error('Error during the booking process:', error);
        }
    };

    return (

        <button onClick={handleClick}>Checkout</button>
    );
};

export default CheckoutForm;
