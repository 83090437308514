import React from 'react';
import logo from './roeltravel.png';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import SearchForm from './components/SearchForm';
import FlightResults from './components/FlightResults';
import ContactDetails from './components/ContactDetails';
import Confirmation from './components/Confirmation';
import Success from './components/public/Success';
import Cancel from './components/public/Cancel';
import AGB from './components/pages/AGB';
import Datenschutz from './components/pages/Datenschutz';
import Impressum from './components/pages/Impressum';
import Kontakt from './components/pages/Kontakt';
import About from './components/pages/About';

function App() {
    return (
        <div className="App">
            <Router>
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <nav>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">Über uns</Link></li>
                            <li><Link to="/contact">Kontakt</Link></li>
                        </ul>
                    </nav>
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<SearchForm />} />
                        <Route path="/flight-results" element={<FlightResults />} />
                        <Route path="/contact-details" element={<ContactDetails />} />
                        <Route path="/confirmation" element={<Confirmation />} />
                        <Route path="/success" element={<Success />} />
                        <Route path="/cancel" element={<Cancel />} />
                        <Route path="/agb" element={<AGB />} />
                        <Route path="/datenschutz" element={<Datenschutz />} />
                        <Route path="/impressum" element={<Impressum />} />
                        <Route path="/contact" element={<Kontakt />} />
                        <Route path="/about" element={<About />} />
                    </Routes>
                </main>
                <footer>
                    <p>&copy; 2024 Roel Travel. All rights reserved.</p>
                    <nav>
                        <ul>
                            <li><Link to="/agb">AGBs</Link></li>
                            <li><Link to="/datenschutz">Datenschutzerklärung</Link></li>
                            <li><Link to="/impressum">Impressum</Link></li>
                        </ul>
                    </nav>
                </footer>
            </Router>
        </div>
    );
}

export default App;
