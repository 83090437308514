import React from 'react';

const Contact = () => {
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        margin: '0',
        padding: '20px',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    };

    const sectionStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        width: '80%',
        maxWidth: '400px'
    };

    const textStyle = {
        fontSize: '20px',
        margin: '10px 0',
    };

    return (
        <div style={containerStyle}>
            <h1>Kontaktoni</h1>
            <div style={sectionStyle}>
                <p style={textStyle}><strong>E-Mail:</strong> info@roeltravel.ch</p>
                <p style={textStyle}><strong>Web</strong>: www.roeltravel.ch</p>
                <p style={textStyle}><strong>Tel:</strong> +41 78 308 08 07</p>
            </div>
            <h2>Kosovo</h2>
            <div style={sectionStyle}>
                <p style={textStyle}><strong>Tel:</strong> +383 45 46 49 49 </p>
            </div>
            <h3>Bank</h3>
            <div style={sectionStyle}>
                <p style={textStyle}>Bank Schweiz</p>
                <p style={textStyle}>Roel Travel</p>
                <p style={textStyle}>PostFinance AG</p>
                <p style={textStyle}> IBAN: CH 97 0900 0000 1630 4570 0</p>
                <p style={textStyle}>BIC: POFICHBEXXX</p>

        </div>

        </div>
    );
};

export default Contact;
