import React from 'react';

const Impressum = () => {
    return (
        <html lang="sq">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Impressum - Roel Travel</title>
            <style>
                {`
                    body {
          
                    }
                            .container {
                            background-color: rgba(255, 255, 255, 0.8);
                            color: #000000;
                            display: flex;
                            align-items: center;
                            margin-bottom: 2px;
                            width: 60%;
                            height: 95%;
                            position: absolute;
                            top: 60%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            borderRadius: 8;
                    }
                    h1, h2 {
                        color: #333;
                    }
                    `}
            </style>
        </head>
        <body>
        <div className="container">
            <div id="impressum">
                <h2>Informacionet mbi Kontaktin dhe Ligjin</h2>
                <p>Këto informacione janë të nevojshme për t'ju siguruar që ne jemi një biznes i ligjshëm dhe i besueshëm. Ju lutemi na kontaktoni nëse keni ndonjë pyetje ose shqetësim.</p>

                <h2>Informacionet mbi Kontaktin</h2>
                <p><strong>Roel Travel</strong></p>
                <p>Adresa: Mülibachstrasse 1, 8185 Winkel</p>
                <p>Email: info@roeltravel.ch</p>
                <p>Telefon: +41 78 308 08 07</p>

                <h2>Personi Përgjegjës</h2>
                <p><strong>Emri i Përgjegjësit:</strong> Qendrim Rexhepi</p>
                <p>Email: info@roeltravel.ch</p>

                <h2>Numri i Regjistrimit të Biznesit</h2>
                <p>Numri i regjistrimit: CHE-371.173.839</p>

                <h2>TVSH</h2>
                <p>Numri i TVSH-së: CHE-371.173.839</p>

                <h2>Autoriteti Mbikëqyrës</h2>
                <p>Autoriteti mbikëqyrës: Qendrim Rexhepi</p>

                <h2>Përgjegjësia për përmbajtjen</h2>
                <p>Ne përpiqemi të sigurojmë që përmbajtja e kësaj faqe të jetë e saktë dhe e përditësuar. Megjithatë, ne nuk mund të marrim përgjegjësi për çdo gabim apo lëshim.</p>

                <h2>Të drejtat e autorit</h2>
                <p>Të gjitha materialet e publikuara në këtë faqe janë të mbrojtura nga të drejtat e autorit dhe nuk mund të riprodhohen pa lejen tonë të shkruar.</p>

                <h2>Ligji i zbatueshëm</h2>
                <p>Këto kushte dhe çdo mosmarrëveshje që lind nga përdorimi i kësaj faqeje do të jenë subjekt i ligjit të Zvicrës dhe do të zgjidhen nga gjykatat kompetente në Zvicër.</p>
            </div>
        </div>
        </body>
        </html>
    );
}

export default Impressum;
