import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Success = () => {
    const location = useLocation();
    const [confirmationCode, setConfirmationCode] = useState('');
    const [flightInfo, setFlightInfo] = useState(null);
    const [returnFlightInfo, setReturnFlightInfo] = useState(null);
    const [email, setEmail] = useState('');
    const [bookingNumber, setBookingNumber] = useState('');
    const [output, setOutput] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');
        setConfirmationCode(sessionId);

        if (sessionId) {
            fetch(`https://c416-2a02-aa11-27e-6300-1119-7c05-66c8-8246.ngrok-free.app/flight-info/${sessionId}`, {headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                })})
                .then(response => response.json())
                .then(data => {
                    setFlightInfo(data.flightInfo);
                    setReturnFlightInfo(data.returnFlightInfo);
                    setEmail(data.email);
                    setBookingNumber(data.bookingNumber);
                    setOutput(data.outputPath)
                    // Send email after flight info is fetched
                    sendEmail(data.email, data.bookingNumber, output);
                })
                .catch(error => console.error('Error fetching flight info:', error));
        }
    }, [location]);

    const sendEmail = async (email, bookingNumber, output) => {
        try {
            const response = await fetch('https://c416-2a02-aa11-27e-6300-1119-7c05-66c8-8246.ngrok-free.app/send-email',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "ngrok-skip-browser-warning": "69420"
                },
                body: JSON.stringify({ sessionId: confirmationCode, email: email, bookingNumber: bookingNumber , output: output}),
            });

            if (response.ok) {
                setEmailSent(true);
            } else {
                console.error('Error sending email:', await response.json());
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <div className="section">
            <h1>Pagesa e suksesshme</h1>
            <p>Faleminderit për blerjen tuaj!</p>
            {confirmationCode && (
                <div className="section">
                    <p>Kodi juaj i konfirmimit është:</p>
                    <strong>{bookingNumber}</strong>
                </div>
            )}
            {flightInfo && (
                <div className="section">
                    <p>{flightInfo.origin.name.de} - {flightInfo.destination.name.de}</p>
                    <p>{flightInfo.number} {new Date(flightInfo.departure).toLocaleString()} {new Date(flightInfo.arrival).toLocaleString()}</p>
                </div>
            )}
            {returnFlightInfo && (
                <div className="section">
                    <p>{returnFlightInfo.origin.name.de} - {returnFlightInfo.destination.name.de}</p>
                    <p>{returnFlightInfo.number} {new Date(returnFlightInfo.departure).toLocaleString()} {new Date(returnFlightInfo.arrival).toLocaleString()}</p>
                </div>
            )}
            {emailSent && <p>Confirmation email sent!</p>}
        </div>
    );
};

export default Success;
