import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles_search.css';

function SearchForm() {
    const navigate = useNavigate(); // Change history to navigate
    const [departureDate, setDepartureDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [departureAirport, setDepartureAirport] = useState('');
    const [arrivalAirport, setArrivalAirport] = useState('');
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [flightType, setFlightType] = useState('one-way');

    const handleSubmit = (e) => {
        e.preventDefault();

        const departureDate = e.target.departure_date.value;
        const returnDate = e.target.return_date.value;
        const departureAirport = e.target.departure_airport.value;
        const arrivalAirport = e.target.destination_airport.value;
        const adults = e.target.ANZERW.value;
        const children = e.target.ANZCHD.value;
        const infants = e.target.ANZINF.value;

        // Navigate to the flight-results route with query parameters
        navigate(`/flight-results?departureDate=${departureDate}&returnDate=${returnDate}&departureAirport=${departureAirport}&arrivalAirport=${arrivalAirport}&adults=${adults}&children=${children}&infants=${infants}&flightType=${flightType}`);
    };

    function toggleReturnDate() {
        const radioValue = document.querySelector('input[name="flight_type"]:checked').value;
        const returnDateInput = document.getElementById('return_date');
        const returnDateInputLabel = document.getElementById('return_date_label');

        if (radioValue === 'return') {
            returnDateInput.style.display = 'inline-block';
            returnDateInputLabel.style.display = 'block';
            setFlightType('return')
        } else {
            returnDateInput.style.display = 'none';
            returnDateInputLabel.style.display = 'none';
            setFlightType('one-way')
        }
    }

    useEffect(() => {
        // Set default value for departure date input to today's date
        const today = new Date().toISOString().split('T')[0];
        document.getElementById('departure_date').value = today;
        document.getElementById('return_date').value = today;
    }, []); // Run this effect only once on component mount

    const handleChange = (e, setter) => {
        setter(e.target.value);
    };
    return (
        <div className="search-form">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <label>
                        <input
                            type="radio"
                            name="flight_type"
                            id="FLGARTNisje dhe Kthim"
                            value="return"
                            checked={flightType === 'return'}
                            className="option-input"
                            onChange={toggleReturnDate}
                        />
                        Nisje dhe Kthim
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="flight_type"
                            id="FLGARTNjdrejtimshe"
                            value="one-way"
                            className="option-input"
                            onChange={toggleReturnDate}
                        />
                        Njëdrejtimshe
                    </label>
                </div>
                <div className="row">
                    <label htmlFor="departure_airport">Nga:</label>
                    <select
                        size="1"
                        id="departure_airport"
                        name="departure_airport"
                        required
                        onChange={(e) => handleChange(e, setDepartureAirport)}
                    >
                        <option value="initial">🛫 Nga</option>
                        <option value="PRN">PRISHTINA (PRN)</option>
                        <option value="GVA">GENEVE/Genf (GVA)</option>
                        <option value="ZRH">ZÜRICH (ZRH)</option>
                    </select>

                    <label htmlFor="destination_airport">Në:</label>
                    <select
                        size="1"
                        id="destination_airport"
                        name="destination_airport"
                        required
                        onChange={(e) => handleChange(e, setArrivalAirport)}
                    >
                        <option value="initial">🛬 Në</option>
                        <option value="PRN">PRISHTINA (PRN)</option>
                        <option value="GVA">GENEVE/Genf (GVA)</option>
                        <option value="ZRH">ZÜRICH (ZRH)</option>
                    </select>
                </div>
                <div className="row">
                    <label htmlFor="departure_date" id="departure_date_label">Nisja:</label>
                    <input
                        type="date"
                        className="form-control"
                        id="departure_date"
                        name="departure_date"
                        required
                        onChange={(e) => handleChange(e, setDepartureDate)}
                    />

                    <label htmlFor="return_date" id="return_date_label">Kthimi:</label>
                    <input
                        type="date"
                        className="form-control ret"
                        id="return_date"
                        name="return_date"
                        onFocus={(e) => e.target.type = 'date'}
                        onChange={(e) => handleChange(e, setReturnDate)}
                    />
                </div>
                <div className="row">
                    <label htmlFor="adults" className="d-inline-flex col-auto">I rritur</label>
                    <select
                        className="form-select"
                        id="adults"
                        name="ANZERW"
                        onChange={(e) => handleChange(e, setAdults)}
                    >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                </select>

                <label htmlFor="children" className="d-inline-flex col-auto">Fëmijë (2 - 12)</label>
                <select
                    className="form-select"
                    id="children"
                    name="ANZCHD"
                    onChange={(e) => handleChange(e, setChildren)}
                    >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                </select>

                <label htmlFor="infants" className="d-inline-flex col-auto">Foshnje</label>
                <select
                    className="form-select"
                    id="infants"
                    name="ANZINF"
                    onChange={(e) => handleChange(e, setInfants)}
                >
                    <option value="0">0</option>
                    <option value="1">1</option>
                </select>
            </div>
                <div className="row">
                    <button type="submit" className="btn btn-primary">Kërko Fluturimin</button>
                </div>
        </form>
        </div>
    );
}

export default SearchForm;
